import OpenAI from 'openai';

const OPENAI_API_KEY = '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';

const openai = new OpenAI({
  apiKey: OPENAI_API_KEY,
  dangerouslyAllowBrowser: true,
});

const getTextV4 = async prompt => {
  const r = await openai.chat.completions.create({
    model: 'o3-mini',
    messages: [
      {
        role: 'user',
        content: prompt,
      },
    ],
  });

  return r.choices[0].message.content.trim();
};

const actionsOpenai = {
  getTextV4,
};

export default actionsOpenai;
